import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import styled from 'styled-components';
import Preview from 'rev.sdk.js/Components/RichTextPreview';
import SEO from '../../components/seo';
import {parse} from 'query-string';
import {AlertCircle} from '@styled-icons/ionicons-outline';
const config = require('../../../data/config.json');

async function req({url, method = 'GET', data}) {
  const resp = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    ...(method !== 'GET' && {body: JSON.stringify(data)}),
  });

  if (200 <= resp.status && resp.status < 400) {
    return resp.json();
  }

  const err = {status: resp.status};
  err.response = await resp.json();

  throw err;
}

function getHeight() {
  if (typeof document !== 'undefined') {
    //Calculate the maximum height of the page
    var body = document.body,
      html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );

    return height;
  }

  return 100;
}

function Result({title, icon}) {
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 40,
        }}>
        {icon}
        <div
          style={{
            textAlign: 'center',
            color: '#999',
            marginTop: 10,
            fontSize: 30,
          }}>
          {title}
        </div>
      </div>
    </Wrapper>
  );
}

export default function PreviewPage(props) {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = parse(props.location.search);
  const service = useRef(null);
  const outer = useRef(null);

  const fetchDocument = useCallback(async () => {
    const collectionName = 'Article_Default';
    setLoading(true);

    try {
      if (service.current) {
        const resp = await req({
          url: `${service.current.domain}/${service.current.version}/document/${collectionName}/find-one?client_id=${params.client}`,
          method: 'POST',
          data: {
            query: {id: params.id},
          },
        });

        setArticle(resp);
      }
    } catch (ex) {
      const errMsg = `API Fail: ${JSON.stringify(ex, null, 2)}`;
      console.warn(errMsg);
    }
    setLoading(false);
  }, [params.id, params.client]);

  useEffect(() => {
    if (!params.client) {
      return alert('需帶入client');
    }

    const getService = async () => {
      const services = await req({
        url: `${config.serviceHost}/service?client_id=${params.client}`,
      });
      service.current = services.find((sv) => sv.service_name === 'jstorage');
    };

    (async function () {
      await getService();
      await fetchDocument();
    })();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [params.client, fetchDocument]);

  // make sure connection
  useEffect(() => {
    function eventHandler(event) {
      // Need to check for safety as we are going to process only our messages
      // So Check whether event with data(which contains any object) contains our message here its "frameHeight"
      if (event.data === 'frameHeight') {
        outer.current = event.source;

        //event.source contains parent page window object
        //which we are going to use to send message back to main page here "abc.com/page"

        //parentSourceWindow = event.source;

        // Send height back to parent page "abc.com/page"
        event.source.postMessage({frameHeight: getHeight()}, '*');
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('message', eventHandler);
      return () => window.removeEventListener('message', eventHandler);
    }
  }, []);

  useEffect(() => {
    if (outer.current) {
      outer.current.postMessage({frameHeight: getHeight()}, '*');
    }
  }, [loading, article]);

  if (loading) {
    return (
      <Wrapper>
        <Loader width="200" />
        <Loader />
        <Loader style={{height: 80, width: 180}} delay={0.9} />
        <Loader width={80} delay={0.4} />
        <Loader style={{height: 300, width: '100%'}} delay={0.4} />
        <Loader delay={0.2} />
        <Loader width={220} delay={0.8} />
        <Loader width={300} delay={0.7} />
        <Loader width={280} delay={0.9} />
      </Wrapper>
    );
  }

  if (!article) {
    return (
      <Result
        title="找不到此文章"
        icon={<AlertCircle size={40} color="#999" />}
      />
    );
  }

  return (
    <>
      <SEO title={article.title || 'Revteltech Dashbhard'} />

      <Wrapper>
        <Preview content={article.content} readOnly />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  height: 100%;
  max-width: 1240px;
  margin: 0 auto;
`;

const Loader = styled.div`
  background-color: #f5f5f5;
  height: 20px;
  width: ${(props) => props.width || '50'}px;
  margin-bottom: 20px;

  @keyframes spark {
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }

  animation: spark 1s linear infinite;
  animation-delay: ${(props) => props.delay || 0}s;
`;
